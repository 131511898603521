import React from "react";
import Dropzone from "react-dropzone-uploader";

export default function Images({token, selectedDock}) {
  const backgroundImage = (innerHTML) => {
    const el = document.getElementById('backgroundImage')
    el.innerHTML = innerHTML
    el.className = 'show'
    el.style.visibility = "visible"
    setTimeout(() => {
      el.className = el.className.replace('show', '')
      el.style.visibility = "hidden"
    }, 3000)
  }

  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
		return { url: '/image/' + selectedDock, headers:{"Authorization": "Bearer " + token}}
  }

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      backgroundImage(`${meta.name} Sent to Dock!`)

      backgroundImage(`<h5>Success</h5>Image '${meta.name}' has been sent to the dock`)
      remove()
    } else if (status === 'aborted') {
      backgroundImage(`<h5>Failure</h5>Image '${meta.name}' has not been sent to the dock`)
    }
  }

  const badFile = (fileWithMeta, reason) => {
    backgroundImage(`<h5>Failure</h5>Image is not valid: ${reason}`)
    fileWithMeta.remove()
    return reason
  }

  const validate = (fileWithMeta) => {
    if (fileWithMeta.meta.type !== 'image/png' &&
        fileWithMeta.meta.type !== 'image/jpeg' &&
        fileWithMeta.meta.type !== 'image/jpg ') {
      return badFile(fileWithMeta, 'File is not a PNG/JPG')
    }
    if ((fileWithMeta.meta.width !== 1920) || (fileWithMeta.meta.height !== 1080)) {
      return badFile(fileWithMeta, `File is wrong dimension of ${fileWithMeta.meta.width}x${fileWithMeta.meta.height}`)
    }
    return false
  }

  return (
      <div className="text-center">
        <div id="backgroundImage" style={{
          visibility: "hidden",
          minWidth: "250px",
          marginLeft: "-125px",
          backgroundColor: "#333",
          color: "#fff",
          textAlign: "left",
          borderRadius: "2px",
          padding: "16px",
          position: "fixed",
          zIndex: 1,
          right: "5%",
          bottom: "30px",
          fontSize: "17px",
          fontFamily: "'Helvetica', sans-serif",
        }}/>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          validate={validate}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          ignoreHiddenFiles={true}
          inputContent={(files, extra) => (extra.reject ? 'PNG/JPG file only' : 'Select PNG/JPG')}
          styles={{
            dropzoneActive: { borderColor: 'green' },
            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
            inputLabel: (files, extra) => (extra.reject ? { color: 'red', fontSize: '16px' } : { fontSize: '16px' }),
          }}
        />
      </div>
  );
}
