import 'react-dropzone-uploader/dist/styles.css'
import "./App.css";
import Login from "./components/Login/Login"
import Images from "./components/Images";
import TextMessage from "./components/TextMessage";
import AppCode from "./components/AppCode";
import DownstreamDevices from "./components/DownstreamDevices";
import React, { useEffect, useState } from "react";
import { DockSelection } from "./components/DockInfo";

let refreshTimer = undefined
let aborter = new AbortController()

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [docksData, setDocksData] = useState();
  const [dockData, setDockData] = useState();
  const [availableApps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedDock, setSelectedDock] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const REFRESH_INTERVAL = 5000

  const handleLogout = async () => {
    fetch("/logout", {
      method: "POST",
      body: '',
    })
    .then((response) => {
      localStorage.removeItem("token")
      localStorage.removeItem("lastSelectedDock")
      setToken(null)
      setSelectedDock(null)
    })
  };

  function refresh() {
    setRefreshCounter((counter) => counter + 1)  // Triggers useEffect!
  }

  // Fetch the data here
  useEffect(() => {
    if (!!token) {
      clearTimeout(refreshTimer)
      // Timeouts only get set after a useEffect finishes, and are cleared when a useEffect starts.
      // Therefore a timeout useEffect cannot abort an existing fetch.
      // So if a fetch is being aborted, the current useEffect was due to user action.
      // User action that has happened after the prior fetch will mean any data that the prior fetch will return will be outdated. So abort it!
      aborter.abort()
      aborter = new AbortController()
      fetch("/docks", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        signal: aborter.signal
        }
      )
      .then((response) => {
        if (response.status === 401){
          console.log("logout")
          handleLogout();
          return Promise.reject(response);
        }
        return response.json()
      })
      .then((json) => {
        if (selectedDock === null && json[0] !== undefined) {
          const dockId = json[0].id;
          setSelectedDock(dockId);
        }
        if (Array.isArray(json)) {
          setDocksData(json);
          setDockData(json.find((dock) => dock.id === selectedDock));
        }
        refreshTimer = setTimeout(refresh, REFRESH_INTERVAL)
      })
      .catch((err) => {
        console.log(err.message)
        if (err.name !== 'AbortError') {
          refreshTimer = setTimeout(refresh, REFRESH_INTERVAL)
        }
      })

      fetch("/pythonapps", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        signal: aborter.signal
      })
        .then((response) => response.json())
        .then((data) => {
          setApps(data.apps);
        })
        .catch((error) => console.log(error));
    }
  }, [token, selectedDock, refreshCounter])


  useEffect(() => {
    if (!!token) {
      fetch("/pythonapps", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        signal: aborter.signal
      })
        .then((response) => response.json())
        .then((data) => {
          setApps(data.apps);
        })
        .catch((error) => console.log(error));
    }
  }, [token, selectedDock, refreshCounter])


  if (!token) {
    return (
      <main className="App">
        <Login setToken={setToken} />
      </main>
    );
  }

  return (
    <main className="App">
      <h3>
        <img src="synaptics-logo-full-color.png" height="100" alt="" />
      </h3>
      <div className="line">
        <h2>Dock Selection</h2>
        <DockSelection docksData={docksData} dockData={dockData} setSelectedDock={setSelectedDock}/>
        {/* <DockInfo dockData={dockData}/> */}
        <div style={{height:"10px"}}/>
      </div>
      <div className="line">
      <div><h2>Application Code</h2></div>
        <AppCode token={token} selectedDock={selectedDock} availableApps={availableApps} selectedApp={selectedApp} setSelectedApp={setSelectedApp}/>
        <div style={{height:"10px"}}/>
      </div>
      {<div className="line">
      <h2>Firmware Version</h2>
        <DownstreamDevices token={token} dockData={dockData} selectedDock={selectedDock}/>
      </div>}
      <div className="line">
        <h2>Text Message</h2>
        <TextMessage token={token} selectedDock={selectedDock}/>
      </div>
      <div className="line">
      <div><h2>Splash Screen</h2></div>
        <Images token={token} selectedDock={selectedDock}/>
      </div>
      <div hidden>
        {refreshCounter}
      </div>
      <button onClick={handleLogout} className="logout">
        Logout
      </button>
      <hr/>
      <div className="documentation">
        <h6><a href="/documentation/welcome" target="_blank">SDK Documentation</a></h6>
        ©2024 Synaptics Incorporated. All rights reserved.
      </div>
      <hr/>
    </main>
  );
}

export default App;
