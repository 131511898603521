import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function getLongestNameLength(docksData) {
  if ((docksData === undefined) || (docksData.length === 0)) {
    return 0
  }
  const lengths = docksData.map((dock) => {
    return dock.name.length
  })
  return Math.max(...lengths)
}

function getDisplayName(dock, longestNameLength) {
  const numSpaces = Math.max(longestNameLength - dock.name.length, 0)
  const spaces = ' '.repeat(numSpaces)
  return dock.name + spaces + ' (' + dock.id.substring(0, 8) + '...)'
}

export function DockSelection({docksData, dockData, setSelectedDock}) {
  const [availableDocks, setAvailableDocks] = useState([])

  let longestNameLength = getLongestNameLength(docksData)

  useEffect(() => {
    const dropdownOptions = () => {
      const docks = []
      if(docksData !== undefined && docksData.msg === undefined) {
        docksData.forEach(function (dock) {
          docks.push(
            <Dropdown.Item style={{fontFamily: "monospace"}} key={dock.id}>
              <div style={{whiteSpace: "pre"}} onClick={() => setSelectedDock(dock.id)}>{getDisplayName(dock, longestNameLength)}</div>
            </Dropdown.Item>
          )
        })
        setAvailableDocks(docks)
      }
    }
    dropdownOptions()
  }, [docksData, setSelectedDock, longestNameLength])

  const isDisabled = dockData === undefined
  const displayName = isDisabled ? "Loading..." : getDisplayName(dockData, longestNameLength)
  return (
    <>
      <DropdownButton title={displayName} style={{fontFamily: "monospace"}} disabled={isDisabled}>{availableDocks}</DropdownButton>
    </>
  )
}


export default function DockInfo({dockData}) {
  const [data, setData] = useState("...");

  useEffect(() => {
    const load = () => {
      if(dockData !== undefined) {
        setData(dockData.status);
      }
    };
    load()
  }, [dockData]);

  return (
    <>
      <h3 className="http">{data}</h3>
    </>
  );
}
