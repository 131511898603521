import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";


function sendAppCode(message, token, dockId) {
  fetch("/appcode", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "applicationCode": message,
      "dockId": dockId,
    })
  })
    .then((response) => {
      const el = document.getElementById('AppCodeSendStatus')

      if(response.status === 200) {
        el.innerHTML = "<h5>Success</h5>Application code has been sent to the dock"
      } else {
        el.innerHTML = "<h5>Failure</h5>Application code has not been sent to the dock"
      }
      el.className = 'show'
      el.style.visibility = "visible"
      setTimeout(() => {
        el.className = el.className.replace('show', '')
        el.style.visibility = "hidden"
      }, 3000)
    })
    .catch((error) => console.log(error));
}

function SelectApp(token, setSelectedApp, appName, setMessage) {
  setSelectedApp(appName);

  fetch("/pythonapps/" + appName, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    }
  })
    .then((response) => response.json())
    .then((data) => {
      setMessage(data);
    })
    .catch((error) => console.log(error));
}

export default function AppMessage({token, selectedDock, availableApps, selectedApp, setSelectedApp}) {
  const [message, setMessage] = useState();
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    const dropdownOptions = () => {
      const items = []
      if(availableApps !== undefined && availableApps.msg === undefined) {
        availableApps.forEach(function (app) {
          items.push(
            <Dropdown.Item style={{fontFamily: "monospace"}} key={app}>
              <div style={{whiteSpace: "pre"}} onClick={() => SelectApp(token, setSelectedApp, app, setMessage)}>{app}</div>
            </Dropdown.Item>
          )
        })
        setMenuItems(items)
      }
    }
    dropdownOptions()
  }, [token, availableApps, setSelectedApp])

  const handleAppCode = (newValue) => {
    setSelectedApp("Custom");
    setMessage(newValue);
  };

  const handleAppCodeInput = () => {
    sendAppCode(message, token, selectedDock)
  };

  const isDisabled = availableApps === undefined;
  const displayName = selectedApp === null ? "Custom" : selectedApp;

  return (
    <div>
      Select Application:
      <div className="d-grid gap-1 p-1">
      <DropdownButton title={displayName} style={{fontFamily: "monospace"}} disabled={isDisabled}>{menuItems}</DropdownButton>
      </div>
      <div className="d-grid gap-1 p-1">
          <hr/>
          <AceEditor
            id="app-code"
            mode="python"
            value={message}
            theme="tomorrow"
            onChange={handleAppCode}
            name="app-code"
            width='100%'
            placeholder="Enter Python Application Code Here"
            editorProps={{
               $blockScrolling: true
            }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              highlightActiveLine: true,
              enableSnippets: false,
              fontSize: 14,
              tabSize: 2
            }}
          />
        <hr/>
        <div>
          <div id="AppCodeSendStatus" style={{
            visibility: "hidden",
            minWidth: "150px",
            marginLeft: "-125px",
            backgroundColor: "#333",
            color: "#fff",
            textAlign: "left",
            borderRadius: "2px",
            padding: "16px",
            position: "fixed",
            zIndex: 1,
            right: "5%",
            bottom: "30px",
            fontSize: "17px",
            fontFamily: "'Helvetica', sans-serif",
          }}/>
          <Button variant="primary" className="align-self-stretch"  style={{width: "50%"}} onClick={() => handleAppCodeInput()}>Send Application Code</Button>{' '}
        </div>
      </div>
    </div>
  );
}
