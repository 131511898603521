import React, {useEffect, useState} from "react";

import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from "react-bootstrap/Table";

function DownstreamDevices({token, dockData, selectedDock}) {
  const [targetVersions, setTargetVersions] = useState([]);
  const [dock, setDock] = useState();

  function handleTargetVersionClick(index, newTarget) {
    const nextTargetVersions = targetVersions.map((currentTarget, i) => {
      if (i === index) {
        return newTarget;
      } else {
        return currentTarget;
      }
    })
    setTargetVersions(nextTargetVersions)
  }

  useEffect(() => {
    if (dockData !== undefined) {
      if (dock !== dockData.id) {
        let currentVersions = []
        dockData["downstreamDevices"].forEach((downstreamDevice) => {
          currentVersions.push(downstreamDevice.currentVersion)
        })
        setTargetVersions(currentVersions);
        setDock(dockData.id);
      }
    }
  }, [dock, dockData]);

  return (
    <>
      <Table striped bordered hover>
        <thead>
        <tr key='header'>
          <th>Downstream Device</th>
          <th>Current Version</th>
          <th>Target Version</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          {
            (dockData === undefined ? [] : dockData["downstreamDevices"]).map((downstreamDevice, deviceIndex) => {
            return (
              <tr key={'device' + deviceIndex}>
                <td>
                  {/* Temporary HACK for Computex demo: Renamed VMM7100 to USB Hub 1 */
                    downstreamDevice.deviceId === "VMM7100" ? "USB Hub 1" : downstreamDevice.deviceId === "USB Hub 1"? "USB Hub 3" : downstreamDevice.deviceId}
                </td>
                <td>
                  {downstreamDevice.currentVersion}
                </td>
                <td>
                  {downstreamDevice.availableVersions[0] === undefined ? targetVersions[deviceIndex]:
                    <DropdownButton title={targetVersions[deviceIndex]}>
                      {downstreamDevice.availableVersions.map((version, versionIndex) => {
                        return (
                          <Dropdown.Item eventKey={versionIndex}>
                            <div onClick={() => {
                              handleTargetVersionClick(deviceIndex, version);
                            }}>
                              {version}
                            </div>
                          </Dropdown.Item>)
                      })}
                    </DropdownButton>
                  }
                </td>
                <td>
                  <div id="TextMessageSendStatus" style={{
                    visibility: "hidden",
                    minWidth: "150px",
                    marginLeft: "-125px",
                    backgroundColor: "#333",
                    color: "#fff",
                    textAlign: "center",
                    borderRadius: "2px",
                    padding: "16px",
                    position: "fixed",
                    zIndex: 1,
                    right: "5%",
                    bottom: "30px",
                    fontSize: "17px",
                    fontFamily: "'Helvetica', sans-serif",
                  }}/>
                  {downstreamDevice.availableVersions[0] === undefined?
                    <Button variant="secondary" disabled>
                      Update
                    </Button>
                    :
                    <Button onClick={() => {
                      /* Temporary HACK for Computex demo: Hardcoded to do update for 'USB Hub 1' deviceId only */
                      sendFirmwareUpdateRequest(targetVersions[deviceIndex], "USB Hub 1", token, selectedDock)
                    }}>
                      Update
                    </Button>
                  }
                </td>
              </tr>)
          })}
        </tbody>
      </Table>
      <h5>
      {
        dockData === undefined? "Loading..." : ''
      }
      </h5>
      <p></p>
    </>
  );
}


function sendFirmwareUpdateRequest(version, deviceId, token, dockId){
  console.log("sending Firmware Update Request for : \n\tDownstream Device = " + deviceId + "\n\tNew Firmware Version : " + version);

  fetch("/firmware", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "type": "firmware",
      "device_id": deviceId,
      "version": version,
      "dock_id": dockId,
    })
  })
    .then((response) => {
      const el = document.getElementById('AppCodeSendStatus')

      if(response.status === 201) {
        el.innerHTML = "<h5>Success</h5>Firmware update has been sent to the dock"
      } else {
        el.innerHTML = "<h5>Failure</h5>Firmware update has not been sent to the dock"
      }
      el.className = 'show'
      el.style.visibility = "visible"
      setTimeout(() => {
        el.className = el.className.replace('show', '')
        el.style.visibility = "hidden"
      }, 3000)
    })
    .catch((error) => console.log(error));
}

export default DownstreamDevices;
